export default {
    computed: {
        userId() {
            const auth = this.$store.getters["auth/getAuth"];
            return auth.userId;
        },
        userLocations() {
            const auth = this.$store.getters["auth/getAuth"];
            return auth.locations;
        },
        userGroup() {
            const auth = this.$store.getters["auth/getAuth"];
            return auth.groupId;
        }
    }
};
