<template>
    <MainContainer>
        <div class="exams">
            <h2>Prüfungen</h2>
            <b-row class="my-4">
                <b-col md="9">
                    <b-row>
                        <b-col cols="12">
                            <h5>Filter</h5>
                        </b-col>
                    </b-row>
                    <b-row>
                        <!--<b-col cols="4">
                            <h6>Typen</h6>
                            <CheckboxSelect
                                :options="typesSelect"
                                v-model="filter.type"
                                :size="size"
                            />
                        </b-col>-->
                        <b-col cols="4">
                            <h6>Prüfungsarten</h6>
                            <CheckboxSelect
                                :options="typeSelect"
                                v-model="filter.type"
                                :size="size"
                            />
                        </b-col>
                        <b-col cols="4" v-if="checkRight('write')">
                            <h6>Standorte</h6>
                            <CheckboxSelect
                                :options="locationsSelect"
                                v-model="filter.locations"
                                :size="size"
                            />
                        </b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col>
                            <b-button :size="size" @click="applyFilter"
                                >Filter anwenden</b-button
                            >
                        </b-col>
                    </b-row>
                </b-col>
                <b-col md="3">
                    <h5>Funktionen</h5>
                    <b-dropdown
                        text="Neue Prüfung"
                        :size="size"
                        variant="outline-primary"
                    >
                        <b-dropdown-item
                            :disabled="!checkRight('write')"
                            :to="{ path: `/exams/new` }"
                        >
                            Erstellen
                        </b-dropdown-item>
                        <b-dropdown-divider />
                        <b-dropdown-item @click="openCambridgeCreationModal">
                            Cambridge
                        </b-dropdown-item>
                        <b-dropdown-item>
                            telc
                        </b-dropdown-item>
                        <b-dropdown-item>
                            Goethe
                        </b-dropdown-item>
                        <b-dropdown-item>
                            Xpert
                        </b-dropdown-item>
                    </b-dropdown>
                    <b-button
                        v-if="checkRight('delete')"
                        class="ml-1"
                        variant="outline-danger"
                        size="sm"
                        :disabled="!(selected.length > 0)"
                        @click="openDeleteModal({ multi: true })"
                        >Auswahl löschen</b-button
                    >
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12" class="my-4">
                    <TableFilter v-model="filter.pattern" />
                </b-col>
            </b-row>
            <b-table
                :items="exams"
                :fields="currentSelectedFields"
                :filter="filter.pattern"
                :busy="busy.data"
                :small="size == 'sm'"
                select-mode="multi"
                sort-by="lastname"
                ref="selectableTable"
                empty-text="Keine Daten vorhanden"
                empty-filtered-text="Für den gewählten Filter wurden keine Daten gefunden"
                foot-clone
                no-footer-sorting
                selectable
                bordered
                striped
                responsive
                show-empty
                :tbody-tr-class="rowClass"
                @row-selected="onRowSelected"
            >
                <template #thead-top="{ columns }">
                    <b-tr>
                        <b-td>
                            <p class="small m-0">Gesamt: {{ exams.length }}</p>
                            <p class="small m-0">
                                Gewählt:
                                <template v-if="selected.length > 0"
                                    >{{ selected.length }}
                                </template>
                                <template v-else>-</template>
                            </p>
                        </b-td>
                        <b-td :colspan="currentRecordFields.length"></b-td>
                        <b-td class="text-right"
                            ><b-dropdown
                                variant="outline-secondary"
                                text="Spaltenauswahl"
                                :size="size"
                            >
                                <b-dropdown-form>
                                    <b-form-checkbox-group
                                        v-model="selectedFields"
                                        :options="recordFields"
                                        value-field="key"
                                        text-field="label"
                                    ></b-form-checkbox-group>
                                </b-dropdown-form>
                            </b-dropdown>
                        </b-td>
                    </b-tr>
                </template>

                <template #table-busy>
                    <div class="text-center text-secondary my-2">
                        <b-spinner class="mr-1 align-middle" small></b-spinner>
                        <strong>Daten werden geladen...</strong>
                    </div>
                </template>

                <template #head(__selected)>
                    <input
                        type="checkbox"
                        v-model="selectAll"
                        :true-value="true"
                        :false-value="false"
                        @change="toggleAllSelectedRows"
                    />
                </template>

                <template #cell(__selected)="{ rowSelected }">
                    <input
                        type="checkbox"
                        :checked="rowSelected"
                        :true-value="true"
                        :false-value="false"
                        disabled
                    />
                </template>

                <template #cell(pending)="data">
                    <template v-if="data.value == 0">
                        <font-awesome-icon icon="circle" class="text-success" />
                    </template>
                    <template v-else>
                        <font-awesome-icon icon="circle" class="text-danger" />
                    </template>
                </template>

                <template #cell(exam_date)="data">
                    {{ formatDate(data.value) }}
                </template>

                <template #cell(type)="data">
                    {{ typeName(data.value) }}
                </template>

                <template #cell(locations)="data">
                    <a
                        v-for="location_id in data.value"
                        :key="location_id"
                        href="#"
                        class="d-block"
                    >
                        {{ locationName(location_id) }}
                    </a>
                </template>

                <template #cell(__actions)="data">
                    <b-button
                        v-if="data.item.pending == 1 && checkRight('write')"
                        variant="outline-secondary"
                        size="sm"
                        class="mx-1"
                        @click="openConfirmPendingModal(data.item.id)"
                    >
                        Änderung zulassen
                    </b-button>
                    <b-button
                        v-if="checkRight('write')"
                        variant="outline-info"
                        size="sm"
                        class="mx-1"
                        @click="openConfirmDuplicateModal(data.item.id)"
                        >Duplizieren</b-button
                    >
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        class="mx-1"
                        :to="{ path: `/exams/${data.item.id}` }"
                        >Bearbeiten</b-button
                    >
                    <b-button
                        v-if="checkRight('delete')"
                        variant="outline-danger"
                        size="sm"
                        class="mx-1"
                        @click="openDeleteModal({ record: data.item })"
                        >Löschen</b-button
                    >
                </template>
            </b-table>

            <ConfirmModal
                id="confirm-duplicate-modal"
                title="Formular duplizieren"
                :bisy="busy.mutation"
                @ok="duplicateExam"
            >
                Die Veranstaltung mit dem Titel "{{ examTitle(duplicateId) }}"
                wirklich duplizieren?
            </ConfirmModal>

            <ConfirmModal
                id="confirm-pending-modal"
                :busy="busy.mutation"
                title="Prüfung zulassen"
                @ok="confirmExam"
            >
                Soll diese Prüfung zugelassen werden? Der*die Antragsteller*in
                wird per Mail informiert.
            </ConfirmModal>

            <DeleteModal
                :mode="modificationMode"
                :record="modificationRecord"
                :fields="currentRecordFields"
                :selected="selected"
                :busy="busy.mutation"
                @ok="deleteRecord"
            >
            </DeleteModal>

            <CambridgeModal></CambridgeModal>
        </div>
    </MainContainer>
</template>

<script>
import ModelView from "@/mixins/ModelView/ModelView";
import Size from "@/mixins/Size/Size";
import DateFormat from "@/mixins/Date/Date";
import Rights from "@/mixins/Rights/Rights";
import User from "@/mixins/User/User";

import MainContainer from "@/components/MainContainer/MainContainer";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import TableExport from "@/components/TableExport/TableExport";
import TableFilter from "@/components/TableFilter/TableFilter";
import ConfirmModal from "@/components/ConfirmModal/ConfirmModal";
import DeleteModal from "@/components/DeleteModal/DeleteModal";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";
import CambridgeModal from "@/components/ExamsCreationViews/Cambridge/CambridgeModal";
import { mapActions, mapGetters } from "vuex";

import "./Exams.scss";

export default {
    name: "Exams",
    components: {
        MainContainer,
        FormGroupBuilder,
        TableExport,
        TableFilter,
        ConfirmModal,
        DeleteModal,
        CheckboxSelect,
        CambridgeModal
    },
    mixins: [ModelView, Size, DateFormat, Rights, User],
    data() {
        return {
            duplicateId: null,
            confirmId: null,
            filter: {
                pattern: null,
                type: [],
                locations: []
            },
            fields: [
                {
                    key: "__selected",
                    label: "",
                    class: "text-center",
                    static: true,
                    position: "prepend"
                },
                {
                    key: "id",
                    label: "ID",
                    sortable: true
                },
                {
                    key: "pending",
                    label: "Ausstehend",
                    sortable: true
                },
                {
                    key: "title",
                    label: "Titel",
                    sortable: true
                },
                {
                    key: "token",
                    label: "Veranstaltungsnummer",
                    sortable: true
                },
                {
                    key: "exam_date",
                    label: "Prüfungstermin",
                    sortable: true
                },
                {
                    key: "starttime",
                    label: "Uhrzeit",
                    sortable: true
                },
                {
                    key: "endtime",
                    label: "Uhrzeit",
                    sortable: true
                },
                {
                    key: "type",
                    label: "Typ",
                    sortable: true
                },
                {
                    key: "locations",
                    label: "Standorte"
                },
                {
                    key: "__actions",
                    label: "Aktionen",
                    class: "text-right",
                    static: true,
                    position: "append"
                }
            ],
            selectedFields: [
                "id",
                "pending",
                "title",
                "token",
                "exam_date",
                "starttime",
                "endtime",
                "type",
                "locations"
            ],
            actions: {
                delete: "exams/deleteData",
                deleteMulti: "exams/deleteMultipleData"
            }
        };
    },
    computed: {
        ...mapGetters({
            busy: "exams/getBusy",
            exams: "exams/getData",
            examTitle: "exams/getTitle",
            typeName: "examsTypes/getTypeName",
            typeSelect: "examsTypes/getSelectData",
            locationName: "examsCambridgeSubcentre/getDataName",
            locationsSelect: "examsCambridgeSubcentre/getSelectData"
        }),
        dataAvailable() {
            return this.exams.length > 0;
        }
    },
    created() {
        if (this.checkGroup("vhs")) {
            this.filter.locations = this.userLocations;
        }
        this.fetch();
        this.fetchTypes();
        this.fetchLocations();
    },
    methods: {
        ...mapActions({
            duplicate: "exams/duplicateData",
            confirm: "exams/confirmPendingData",
            fetchExams: "exams/fetchData",
            fetchTypes: "examsTypes/fetchData",
            fetchLocations: "examsCambridgeSubcentre/fetchData"
        }),
        async fetch() {
            await this.fetchExams({ filter: this.filter });
            this.registerRights("exams");
        },
        applyFilter() {
            this.fetch();
        },
        openConfirmDuplicateModal(exam_id) {
            this.duplicateId = exam_id;
            this.$bvModal.show("confirm-duplicate-modal");
        },
        openConfirmPendingModal(exam_id) {
            this.confirmId = exam_id;
            this.$bvModal.show("confirm-pending-modal");
        },
        duplicateExam() {
            this.duplicate(this.duplicateId);
        },
        confirmExam() {
            this.confirm(this.confirmId);
        },
        openCambridgeCreationModal() {
            this.$bvModal.show("cambridge-modal");
        },
        rowClass(item, type) {
            if (item && item.pending == 1) {
                return "table-secondary";
            } else return;
        }
    }
};
</script>
