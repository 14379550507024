<template>
    <b-modal
        :id="id"
        size="xl"
        title="Neue Cambridge Prüfung anlegen"
        :ok-disabled="busy"
        :cancel-disabled="busy"
        @ok="createExam"
    >
        <template>
            <FormGroupBuilder :fields="ioFields" :data="exam" />
            <hr />
            <b>Teilnehmendenliste hochladen</b>
            <b-form-file v-model="excelImport" plain> </b-form-file>
        </template>

        <template #modal-ok>
            <Spinner v-if="busy" small variant="light" />
            Anlegen
        </template>

        <template #modal-cancel>Abbrechen</template>
    </b-modal>
</template>

<script>
import Rights from "@/mixins/Rights/Rights";
import User from "@/mixins/User/User";

import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import Spinner from "@/components/Spinner/Spinner";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "CambridgeModal",
    mixins: [Rights, User],
    components: {
        FormGroupBuilder,
        Spinner
    },
    props: {
        id: {
            type: String,
            default: "cambridge-modal"
        }
    },
    data() {
        return {
            ioFields: {
                title: {
                    key: "title",
                    label: "Titel"
                },
                type: {
                    key: "type",
                    label: "Typ",
                    type: "select",
                    disabled: true,
                    options: [{ value: null, text: "Keine Auswahl" }]
                },
                exam_date: {
                    key: "exam_date",
                    label: "Prüfungsdatum",
                    type: "date"
                },
                starttime: {
                    key: "starttime",
                    label: "Uhrzeit (Beginn)",
                    type: "time"
                },
                endtime: {
                    key: "endtime",
                    label: "Uhrzeit (Ende)",
                    type: "time",
                    disabled: true
                },
                locations: {
                    key: "locations",
                    label: "Standort",
                    type: "checkboxselect",
                    options: []
                }
            },
            exam: {
                type: "camebridge"
            },
            excelImport: null,
            busy: false
        };
    },
    computed: {
        ...mapGetters({
            locationsSelect: "locations/getSelectData"
        }),
        typesSelect() {
            const types = this.$store.getters["examsTypes/getSelectData"];
            types.unshift({ value: null, text: "Keine Auswahl" });
            return types;
        }
    },
    created() {
        this.fetchTypes();
        this.fetchLocations();
        this.checkFields();
    },
    watch: {
        "exam.starttime": {
            handler(val) {
                this.ioFields.endtime.disabled = val ? false : true;
            },
            immediate: true
        },
        typesSelect: {
            handler(val) {
                this.ioFields.type.options = val;
            }
        },
        locationsSelect: {
            handler(val) {
                this.ioFields.locations.options = val;
            }
        }
    },
    methods: {
        ...mapActions({
            fetchTypes: "examsTypes/fetchData",
            fetchLocations: "locations/fetchData"
        }),
        async createExam(event) {
            event.preventDefault();

            this.busy = true;

            var createAction = "exams/createData";
            if (this.checkGroup("vhs")) {
                createAction = "exams/createPendingData";
            }
            const exam = await this.$store.dispatch(createAction, this.exam);

            var uploadSuccessful = null;
            if (exam && this.excelImport) {
                var uploadAction = "examsPersons/importCambridge";
                if (this.checkGroup("vhs")) {
                    uploadAction = "examsPersons/importCambridgePending";
                }
                uploadSuccessful = await this.$store.dispatch(uploadAction, {
                    id: exam.id,
                    file: this.excelImport
                });
            }

            this.busy = false;

            if (exam || uploadSuccessful) {
                this.$bvModal.hide(this.id);
            }
        },
        checkFields() {
            if (this.checkGroup("vhs")) {
                this.ioFields.exam_date.hidden = true;
                this.ioFields.starttime.hidden = true;
                this.ioFields.endtime.hidden = true;
                this.ioFields.locations.hidden = true;
                this.exam.locations = this.userLocations;
            }
        }
    }
};
</script>
